import Cookies from "js-cookie";
const not_logged_in = [
    {
        title: "Login",
        route: "auth-login",
        icon: "LogInIcon",
    },
    {
        title: "Register",
        route: "auth-register",
        icon: "EditIcon",
    },
];

const logged_in = [
    {
        title: "Downloader",
        route: "downloader",
        icon: "DownloadIcon",
    },
    {
        title: "History",
        route: "download-history",
        icon: "ChevronsDownIcon",
    },
    {
        title: "Profile",
        route: "profile",
        icon: "UserIcon",
    },
];

var ext_menu = [];

if (!!Cookies.get("access_token")) {
    ext_menu = logged_in;
} else {
    ext_menu = not_logged_in;
}

export default [
    {
        title: "Home",
        route: "home",
        icon: "HomeIcon",
    },
    {
        title: "Status",
        route: "status",
        icon: "ActivityIcon",
    },
    {
        title: "Plans",
        route: "plans",
        icon: "Link2Icon",
    },
    {
        title: "Resellers",
        route: "resellers",
        icon: "CreditCardIcon",
    },
    ...ext_menu,
    {
        title: "Support",
        route: "support",
        icon: "MailIcon",
    },
    {
        title: "Privacy Policy",
        icon: "ShieldIcon",
        route: "privacy",
    },
    {
        title: "Terms of Use",
        icon: "FileTextIcon",
        route: "terms",
    },
    {
        header: "Our products",
    },
    {
        title: "Leechall.com",
        icon: "StarIcon",
        href: "https://leechall.com/",
    },
];
