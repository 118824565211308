<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center d-none d-lg-flex">
            <b-link :to="{ name: 'plans' }"
                ><b-button variant="outline-primary"
                    ><feather-icon icon="StarIcon" />
                    {{ buttonPre }}
                </b-button></b-link
            >
            <div class="ml-50 font-weight-bold" v-if="expiredPremium">
                Premium:
                <span class="text-primary">{{ expiredPremium }}</span>
            </div>
        </div>

        <!-- Right Col -->
        <b-navbar-nav class="nav align-items-center right-col ml-auto">
            <dark-Toggler />

            <b-nav-item-dropdown right toggle-class="d-flex align-items-center">
                <template #button-content>
                    <feather-icon icon="ClockIcon" size="21" />
                </template>
                <template>
                    <b-dropdown-item link-class="d-flex align-items-center">
                        <feather-icon icon="ClockIcon" class="mr-50" />{{
                            currentTime
                        }}
                    </b-dropdown-item>
                </template>
            </b-nav-item-dropdown>

            <notification-dropdown v-if="isLoggedIn" />

            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p
                            class="user-name font-weight-bolder mb-0"
                            v-if="isLoggedIn"
                        >
                            {{ setName }}
                        </p>
                        <p
                            class="user-name font-weight-bolder mb-0 text-primary"
                            v-else
                        >
                            Guest
                        </p>
                        <span class="user-status"> {{ statusUser }}</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        badge
                        class="badge-minimal"
                        badge-variant="success"
                    />
                </template>
                <template v-if="!isLoggedIn">
                    <b-dropdown-item
                        link-class="d-flex align-items-center"
                        v-for="(item, index) in menuNotLoggedIn"
                        :key="index"
                        :to="item.path"
                        ><feather-icon
                            size="16"
                            :icon="item.icon"
                            class="mr-50"
                        />
                        {{ item.title }}
                    </b-dropdown-item>
                </template>
                <template v-else>
                    <b-dropdown-item
                        link-class="d-flex align-items-center"
                        v-for="(item, index) in menuLoggedIn"
                        :key="index"
                        :to="item.path"
                        ><feather-icon
                            size="16"
                            :icon="item.icon"
                            class="mr-50"
                        />
                        {{ item.title }}
                    </b-dropdown-item>
                </template>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BImg,
    BBadge,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import NotificationDropdown from "./NotificationDropdown.vue";

export default {
    components: {
        BButton,
        BLink,
        BNavbarNav,
        BNavItem,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        BImg,
        BBadge,

        // Navbar Components
        DarkToggler,
        NotificationDropdown,
    },
    directives: {
        Ripple,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    setup() {
        // App Name
        const { appName, appLogoImage } = $themeConfig.app;
        return {
            appName,
            appLogoImage,
        };
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        setName() {
            return this.$store.getters.getUser.name;
        },
        statusUser() {
            const statusText = {
                true: "Premium",
                false: "Free",
            };
            return statusText[this.$store.getters.getUser.has_premium];
        },
        expiredPremium() {
            if (this.$store.getters.getUser.expired_at != undefined)
                return this.$store.getters.getUser.expired_at.slice(0, 10);
        },
        buttonPre() {
            if (this.$store.getters.getUser.has_premium === true)
                return "Renew";
            return "Premium";
        },
    },
    created() {
        setInterval(() => this.updateCurrentTime(), 1000);
    },

    data() {
        return {
            currentTime: this.getCurrentTime(),
            menuNotLoggedIn: [
                {
                    title: "Login",
                    icon: "LogInIcon",
                    path: {
                        name: "auth-login",
                    },
                },
                {
                    title: "Register",
                    icon: "EditIcon",
                    path: {
                        name: "auth-register",
                    },
                },
            ],
            menuLoggedIn: [
                {
                    title: "Profile",
                    icon: "UserIcon",
                    path: {
                        name: "profile",
                    },
                },
                {
                    title: "Logout",
                    icon: "LogOutIcon",
                    path: {
                        name: "auth-logout",
                    },
                },
            ],
        };
    },
    methods: {
        getCurrentTime() {
            return new Date().toISOString().slice(0, 19).replace("T", " ");
        },
        updateCurrentTime() {
            this.currentTime = this.getCurrentTime();
        },
    },
};
</script>
<style></style>
