<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >
        <template #button-content>
            <feather-icon
                :badge="unread"
                badge-classes="bg-danger"
                class="text-body"
                icon="BellIcon"
                size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
            </div>
        </li>

        <!-- Notifications -->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scrollable-container media-list scroll-area"
            tagname="li"
        >
            <!-- System Notifications -->
            <b-link
                v-for="notification in notifications"
                :key="notification.subtitle"
            >
                <b-media>
                    <template #aside>
                        <b-avatar size="32" variant="light-success">
                            <feather-icon icon="CheckIcon" />
                        </b-avatar>
                    </template>
                    <p class="media-heading">
                        <span
                            :class="{
                                'font-weight-bolder': !notification.read_at,
                            }"
                        >
                            {{ notification.data.title }}
                        </span>
                    </p>
                    <small class="notification-text">{{
                        notification.created_at.slice(0, 10)
                    }}</small>
                </b-media>
            </b-link>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer">
            <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-variant="primary"
            >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click="onMarkAsRead"
                    :disabled="unread === 0"
                    >Read all notifications</b-button
                >
            </b-overlay>
        </li>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        VuePerfectScrollbar,
        BButton,
        BFormCheckbox,
        BOverlay,
    },
    directives: {
        Ripple,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            unread: 0,
            notifications: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
        };
    },
    methods: {
        onMarkAsRead() {
            this.loading = true;
            this.$store
                .dispatch("markAsRead")
                .then((res) => {
                    if (res.data.success) {
                        this.loading = false;
                        this.fetchData();
                    }
                })
                .catch((err) => console.log(err));
        },

        fetchData() {
            this.$store
                .dispatch("fetchNotifications")
                .then((res) => {
                    this.notifications = res.data.notifications;
                    this.unread = res.data.unread;
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style></style>
